.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.p-4-8 {
  padding: 4px 8px;
}

.p-5 {
  padding: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.padding {
  padding: 20px;
}

.margin {
  margin: 0 20px;
}

.mr {
  margin-right: 20px;
}

.pl {
  padding-left: 8px !important;
}
.mb-5 {
  margin-bottom: 20px !important;
}
