.dark {
  color: $color-dark;
}

.white {
  color: $color-white;
}

.bg-white {
  background-color: $color-white;
}

.bg-dark {
  background-color: rgb(86, 23, 23);
}

.bg-gray {
  background-color: $color-gray;
}

.bg-red {
  background-color: $color-red;
}

.bg-green {
  background-color: $color-green;
}
.bg-yellow {
  background-color: rgb(252, 221, 221);
  color: white !important;
}
