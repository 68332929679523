@import "./css/variables.scss";
@import "./css/colors";
@import "./css/layout";
@import "./css/typography";
@import "./css/spacing";
@import "./css/text";
@import "./css/icons";
@import "./css/app";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(240, 239, 239);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  height: 35px;
  border-radius: 10px;
  border: 1px solid grey;
  outline: none;
}

button {
  width: max-content;
  background: rgb(0, 0, 0);
  padding: 10px 25px;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  outline: none;
  border: 1px solid black;
  &:hover {
    background: transparent;
    color: black;
  }
}
.App {
  padding-bottom: 100px;
  max-width: 800px;
  width: 85%;
  margin: 0 auto;
  position: relative;
}

.top_section {
  background-color: #f5f5f5;
  display: flex;
  gap: 30px;
  padding: 20px;
}
